@tailwind base;
@tailwind components;
@tailwind utilities;

.login-con {
  max-width: 500px;
  padding: 10px 20px;
}

.notification {
  position: fixed;
  top: 97px;
  /* left: 44%; */
  margin-left: 18em;
  width: 40em;
  transform: translateX(-50%);
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.notification.success {
  background-color: #4CAF50;
}

.notification .content {
  margin-left: 15px;
}

.notification button.dismiss-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: auto;
  font-weight: bold;
}

.notification button.dismiss-btn:hover {
  text-decoration: underline;
}